import React, { useState, useEffect } from "react";
import { Grid, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Box, Icon, tableCellClasses, TableFooter } from "@mui/material";
import { styled } from "@mui/material/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import CUPagination from "components/CUPagination";
import CUDialog from "components/CUDialog";
import { useNavigate } from "react-router-dom";
import { GET_CUSTOM_FRAME_SIZE_API, DELETE_CUSTOM_FRAME_SIZE_API } from "assets/api/customFrameSizeManagementApi";
import { toast } from "react-toastify";
import { useMaterialUIController } from "context";
import validationMessage from "utils/validationMessage";

function CustomFrameSizeManagement() {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const navigate = useNavigate();

    const [apiCalled, setApiCalled] = useState(false);
    const [frameList, setFrameList] = useState([]);
    const [page, setPage] = useState({
        currentPage: 1,
        dataShowLimit: 10,
        numberOfPages: 0,
    });

    async function getFrameData() {
        const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    const token = userToken?.token;
        if (token) {
            const searchData = {
                page: page.currentPage,
                limit: page.dataShowLimit,
            };
            const frameListData = await GET_CUSTOM_FRAME_SIZE_API(searchData);
            if (frameListData.status === 200) {
                setFrameList(frameListData.data);
                let totalCount = frameListData.count;
                if (totalCount > 0) {
                    let numberOfPages = Math.ceil(totalCount / page.dataShowLimit);
                    setPage((p) => { return { ...page, numberOfPages: numberOfPages } });
                }
                setApiCalled(true);
            } else {
                setFrameList([]);
                setApiCalled(true);
            }
        } else {
            toast.error(validationMessage.PLEASE_LOGIN);
            setApiCalled(true);
          }
    }

    useEffect(() => {
        setApiCalled(false);
        getFrameData();
    }, [page.currentPage]);

    const handlePaginationChange = (e, p) => {
        setPage({ ...page, currentPage: p });
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [deleteFrame, setDeleteFrame] = useState();

    const handleClickDialogOpen = (frameId) => {
        setDeleteFrame(frameId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleDeleteFrame = async (frameId) => {
        const tokenString = localStorage.getItem("token");
        const userToken = JSON.parse(tokenString);
        const token = userToken?.token;
        if (token) {
            const apiResponse = await DELETE_CUSTOM_FRAME_SIZE_API(token, frameId);
            if (apiResponse?.status === 200) {
                toast.success(apiResponse.message);
                setOpenDialog(false);
                getFrameData();
            } else {
                toast.error(apiResponse.message || "Error deleting frame.");
            }
        } else {
            toast.error("Please login.");
        }
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                pt={2}
                                px={2}
                                display="flex"
                                variant="gradient"
                                borderRadius="lg"
                                coloredShadow="info"
                                bgColor="info"
                                justifyContent="space-between"
                                alignItems="center"
                                paddingBottom="15px"
                            >
                                <MDTypography variant="h6" color="white">
                                    Manage Custom Frames
                                </MDTypography>
                                <MDButton variant="gradient" color="dark" onClick={() => navigate("/create-custom-frame")}>
                                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                                    &nbsp;Create a New Frame
                                </MDButton>
                            </MDBox>

                            <MDBox pt={3}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 450 }} aria-label="simple table">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell>Frame Name</StyledTableCell>
                                                <StyledTableCell>Width (cm)</StyledTableCell>
                                                <StyledTableCell>Height (cm)</StyledTableCell>
                                                <StyledTableCell align="center">Actions</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                        {!apiCalled && 
                                            <StyledTableRow>
                                                <TableCell colSpan="9">
                                                    <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: "100%",  // Ensures the Box takes full width of the TableCell
                                                        height: "100%", // Ensures the Box takes full height of the TableCell
                                                    }}
                                                    >
                                                    <CircularProgress />
                                                    </Box>
                                                </TableCell>
                                            </StyledTableRow>
                                        }
                                        {apiCalled && ((frameList && frameList.length > 0) ? frameList.map((row) => (
                                            <StyledTableRow key={row._id}>
                                                <StyledTableCell>{row.name}</StyledTableCell>
                                                <StyledTableCell>{row.width_cm}</StyledTableCell>
                                                <StyledTableCell>{row.height_cm}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <CUDialog
                                                        openDialog={openDialog}
                                                        handleCloseDialog={handleCloseDialog}
                                                        deleteId={deleteFrame}
                                                        handleDelete={handleDeleteFrame}
                                                        headingMessage="Confirmation"
                                                        bodyMessage="Are you sure you want to delete this frame?"
                                                    />
                                                    <MDBox display="flex" justifyContent="center">
                                                        <MDButton variant="text" color={darkMode ? "white" : "dark"} onClick={() => navigate(`/custom-frame/edit/${row._id}`)}>
                                                            <Icon>edit</Icon>&nbsp;edit
                                                        </MDButton>
                                                        <MDButton variant="text" color="error" onClick={() => handleClickDialogOpen(row._id)}>
                                                            <Icon>delete</Icon>&nbsp;delete
                                                        </MDButton>
                                                    </MDBox>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )) : (
                                            <StyledTableRow
                                                key="1"
                                                sx={{
                                                "&:last-child td, &:last-child th": {
                                                    border: 0,
                                                },
                                                }}
                                            >
                                                <TableCell colSpan="9">
                                                    <Box
                                                        sx={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        width: "100%",  // Ensures the Box takes full width of the TableCell
                                                        height: "100%", // Ensures the Box takes full height of the TableCell
                                                        }}
                                                    >
                                                        No Custom Frame Found
                                                    </Box>
                                                </TableCell>
                                            </StyledTableRow>
                                        ))}
                                        </TableBody>
                                        <TableFooter>
                                        <CUPagination
                                          numberOfPages={page.numberOfPages}
                                          currentPage={page.currentPage}
                                          onChange={handlePaginationChange}
                                        />
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default CustomFrameSizeManagement;
