import { Icon, Tooltip } from '@mui/material';
import MDButton from 'components/MDButton';
import React from 'react';

const PDFDownloader = ({ pdfUrl, fileName }) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.target = '_blank';
    link.download = fileName;
    link.click();
  };

  return (
    <Tooltip title={"Download Invoice"}>
      <MDButton color={"dark"} onClick={handleDownload} size={"medium"}><Icon>download</Icon>&nbsp;
Download</MDButton>
    </Tooltip>
  );
};

export default PDFDownloader;
