import { useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useNavigate } from "react-router-dom";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Button, TextField } from "@mui/material";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Logo from "../../../assets/images/logo.jpg";

import {
  ADMIN_LOGIN,
  ADMIN_PASSWORD_CHANGE,
} from "../../../assets/api/adminManagementApi";

const ChangePassword = () => {
  toast.configure({
    autoClose: 17000,
    draggable: true,
  });
  const navigate = useNavigate();
  const [adminList, setAdminList] = useState([]);
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setNewConfirmPassword] = useState("");

  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setOldPasswordError(false);
    setNewPasswordError(false);
    setConfirmPasswordError(false);

    if (old_password === "") {
      setOldPasswordError(true);
    }
    if (new_password === "") {
      setNewPasswordError(true);
    }
    if (confirm_password === "") {
      setConfirmPasswordError(true);
    }
    const payload = { old_password, new_password, confirm_password };
    const tokenString = localStorage.getItem("token");
    const userToken = JSON?.parse(tokenString);
    const token = userToken?.token;

    if (token) {
      const apiResponse = await ADMIN_PASSWORD_CHANGE({
        payload,
        token,
      });
      console.log("ddddddddddddddddddddddddddddd", apiResponse);

      if (apiResponse?.status === 200) {
        toast.success(apiResponse?.message);
        // setToken(apiResponse?.data);
        navigate("/dashboard");
        // window.location.reload(false);
      } else {
        console.log("apiResponse-->> ", apiResponse);
        
        if (apiResponse && apiResponse.message) {
          toast.error(apiResponse.message);
        } else {
          if(apiResponse?.errors[0]?.path == 'confirm_password') {
            console.log("Entere in If Block confirm_password");
            
            toast.error(
              `${apiResponse?.errors[0]?.msg}`
            );
          } else {
            toast.error(
              `${apiResponse?.errors[0]?.path.replaceAll("_", " ")} ${apiResponse?.errors[0]?.msg}`
            );
          }
        }
      }
    }
  };


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDBox
            component="img"
            src={Logo}
            alt="Brand"
            width="8rem"
            height="auto"
          />
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Change Password
          </MDTypography>
        </MDBox>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox>
              <MDBox mb={2}>
                <TextField
                  type="Password"
                  label="Old Password"
                  variant="standard"
                  onChange={(e) => setOldPassword(e.target.value)}
                  value={old_password}
                  error={oldPasswordError}
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <TextField
                  type="Password"
                  label="New Password"
                  variant="standard"
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={new_password}
                  error={newPasswordError}
                  fullWidth
                />
              </MDBox>
              <MDBox mb={2}>
                <TextField
                  type="Password"
                  label="Confirm Password"
                  variant="standard"
                  onChange={(e) => setNewConfirmPassword(e.target.value)}
                  value={confirm_password}
                  error={confirmPasswordError}
                  fullWidth
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <Button
                  className="btn-submit"
                  variant="contained"
                  color="info"
                  fullWidth
                  type="submit"
                >
                  Change Password
                </Button>
              </MDBox>
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </BasicLayout>
  );
};

export default ChangePassword;
