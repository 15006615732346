
// @mui material components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

import {
  Container,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import BasicLayout from "layouts/authentication/components/BasicLayout";

const handleSubmit = async (event) => {
  console.log(event);
  event.preventDefault();

};

const DeleteAccount = () => {
  return (
    <BasicLayout image={bgImage}>
      <Container style={{width: "100%", display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'flex-start'}}>
        {/* General Terms and Conditions */}
        <Card variant="outlined" style={{alignItems: 'center'}} sx={{ marginBottom: 8, width: '750px', textAlign: 'center' }}>
          <CardContent >
            <Typography variant="h2" gutterBottom>
              Delete Account
            </Typography><br/>

            <Typography variant="h4" gutterBottom>
            1. Delete Account
            </Typography>

            <List>
              <ListItem>
                <ListItemText>
                We understand that you may wish to delete your account and ensure your data privacy. To proceed with the deletion of your account, please follow the steps below:<br/><br/>

                • Open Catchy App.<br/>
                • Navigate to the Settings page and Locate "Delete Account" button.<br/>
                • Tap on it to delete your account.<br/>
                • Once confirmed, your account will be deleted from our database.<br/>
                </ListItemText>
              </ListItem>
            </List>
          </CardContent>

          <CardContent>
            <Typography variant="h4" gutterBottom>
            2. Data Retention
            </Typography>

            <List>
              <ListItem>
                <ListItemText>
                Upon deleting your account, the following types of data will be deleted:<br/><br/>

                • Personal information such as your name, email address, and any other details provided during registration.<br/>
                • Any content or data associated with your account within the app.<br/>
                </ListItemText>
              </ListItem>

              {/* Add more ListItems for each point in the special terms and conditions */}
            </List>
          </CardContent>

          <CardContent>
            <Typography variant="h4" gutterBottom>
            3. Retention Period
            </Typography>

            <List>
              <ListItem>
                <ListItemText>
                  We do not retain any data after your account deletion request has been processed.<br/><br/>
                  Thank you for using Catchy!<br/>
                </ListItemText>
              </ListItem>

              {/* Add more ListItems for each point in the special terms and conditions */}
            </List>
          </CardContent>

          {/* <CardContent>
            <List>
              <ListItem>
                <ListItemText>
                After we have deleted your account from our database, we will send a confirmation email to the address provided. This email will serve as confirmation that your account has been successfully deleted.<br/><br/>

                
                </ListItemText>
              </ListItem>
            </List>
          </CardContent> */}
        </Card>

        {/* General Special Terms and Conditions */}

        {/* Add more Card components and Typography components for the remaining content */}
      </Container>
    </BasicLayout>
  );
};

export default DeleteAccount;
