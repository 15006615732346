import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
// import styled from 'styled-components';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// const StyledDateRangePicker = styled.div`
//     .rdrCalendarWrapper {
//         // Add your custom styles here
//     }
// `;

const DateRangePickerComponent = () => {
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    return (
        // <StyledDateRangePicker>
            <DateRange
                editableDateInputs={true}
                onChange={item => setState([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={state}
            />
        // </StyledDateRangePicker>
    );
};

export default DateRangePickerComponent;
