// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import {
  Button,
  CardContent,
  Icon,
  TableFooter,
  Typography,
} from "@mui/material";
import MDButton from "components/MDButton";
import { Link, useNavigate } from "react-router-dom";

//Table :

import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { GET_PRODUCT_API } from "assets/api/productManagementApi";
import CUPagination from "components/CUPagination";
import CUDialog from "components/CUDialog";
import { useMaterialUIController } from "context";
import { DELETE_PRODUCT_API } from "assets/api/productManagementApi";
import { toast } from "react-toastify";
import validationMessage from "utils/validationMessage";
import { GET_ADMIN_LIST } from "assets/api/adminManagementApi";
import { DELETE_ADMIN_API } from "assets/api/adminManagementApi";
import { GET_USER_LIST } from "assets/api/adminManagementApi";
import { GET_USER_EXPORT } from "assets/api/adminManagementApi";

function ManageUserAccounts() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const navigate = useNavigate();
  toast.configure({
    autoClose: 17000,
    draggable: true,
  });

  // function to create a products
  const add = () => {
    console.log("clicked");
    navigate("/create-admin");
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [apiCalled, setApiCalled] = useState(false);
  const [userList, setuserList] = useState([]);
  const [page, setPage] = useState({
    currentPage: 1,
    dataShowLimit: 10,
    numberOfPages: 0,
  });

  // async function getAdminData() {
  const getAdminData = async () => {
    const searchData = {
      page: page.currentPage,
      limit: page.dataShowLimit,
    };
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    const token = userToken?.token;
    if (token) {
      const searchData = {
        page: page.currentPage,
        limit: page.dataShowLimit,
      };
      const adminListData = await GET_USER_LIST(token, searchData);
      if (adminListData?.status === 200) {
        setuserList(adminListData?.data);
        // setuserList([]);
        let totalCount = adminListData?.count;
        if (totalCount > 0) {
          let numberOfPages = Math.ceil(totalCount / page.dataShowLimit);
          setPage((p) => {
            return { ...page, numberOfPages: numberOfPages };
          });
        }
        setApiCalled(true);
      } else {
        setuserList([]);
        setApiCalled(true);
      }
    } else {
      toast.error(validationMessage.PLEASE_LOGIN);
      setApiCalled(true);
    }
  };

  useEffect(() => {
    setApiCalled(false);
    getAdminData();
  }, [page.currentPage]);

  console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww", userList);
  const handlePaginationChange = (e, p) => {
    console.log("Page->> ", e, p);
    setPage({ ...page, currentPage: p });
  };

  // Handle Download
  const handleDownload = async () => {    
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    const token = userToken?.token;
    await GET_USER_EXPORT(token);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState();
  const handleClickDialogOpen = (productId) => {
    setDeleteProduct(productId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteProduct = async (productId) => {
    if (productId) {
      const tokenString = localStorage.getItem("token");
      const userToken = JSON.parse(tokenString);
      const token = userToken?.token;
      if (token) {
        const apiResponse = await DELETE_ADMIN_API(token, productId);
        if (apiResponse?.status === 200) {
          toast.success(apiResponse.message);
          setOpenDialog(false);
          // navigate("/product-management");
          // window.location.reload();
          getAdminData();
        } else {
          if (apiResponse && apiResponse.message) {
            toast.error(apiResponse.message);
          } else {
            toast.error(
              `${apiResponse?.errors[0]?.path.replaceAll("_", " ")} ${apiResponse?.errors[0]?.msg}`
            );
          }
        }
      } else {
        toast.error(validationMessage.PLEASE_LOGIN);
      }
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                pt={2}
                px={2}
                display="flex"
                variant="gradient"
                borderRadius="lg"
                coloredShadow="info"
                bgColor="info"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom="15px"
              >
                <MDTypography variant="h6" color="white">
                  User List
                </MDTypography>
                {/* <Button variant="contained" onClick={add}>Create a New User</Button> */}
                  {/* <Button variant="contained" color="info" onClick={handleDownload} sx={{color: 'white'}}>Export</Button> */}
                  <MDButton variant="gradient" color={"dark"} onClick={handleDownload} size={"small"} ><Icon>download</Icon>&nbsp;Export</MDButton>
              </MDBox>

              <MDBox pt={3}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 450 }} aria-label="simple table">
                    <TableHead>
                      <StyledTableRow className="table-data">
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Profile Picture</StyledTableCell>
                        {/* <StyledTableCell>User Type</StyledTableCell> */}
                        <StyledTableCell>Gender</StyledTableCell>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>Phone</StyledTableCell>

                        {/* <StyledTableCell align="center">
                          Actions
                        </StyledTableCell> */}
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {!apiCalled && 
                        <StyledTableRow>
                          <TableCell colSpan="5">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",  // Ensures the Box takes full width of the TableCell
                                height: "100%", // Ensures the Box takes full height of the TableCell
                              }}
                            >
                              <CircularProgress />
                            </Box>
                          </TableCell>
                        </StyledTableRow>
                      }
                      { apiCalled && ((userList && userList.length > 0) ? (
                          userList.map((row) => (
                            <StyledTableRow
                              key={row._id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <StyledTableCell
                                component="td"
                                scope="row"
                                className="common-25"
                              >
                                {row?.first_name ? row?.first_name + " " + row?.last_name : 'N/A'}
                              </StyledTableCell>
                              <StyledTableCell
                                component="td"
                                scope="row"
                                className="common-25"
                              >
                                { row?.profile_picture ? <img
                                  src={row?.profile_picture ? row?.profile_picture : ""}
                                  alt={""}
                                  style={{ width: "3rem", height: "3rem" }}
                                /> : 'N/A' }
                              </StyledTableCell>
                              {/* <StyledTableCell
                                component="td"
                                scope="row"
                                className="common-25"
                              >
                                {row?.user_type || "N/A"}
                              </StyledTableCell> */}
                              <StyledTableCell
                                component="td"
                                scope="row"
                                className="common-25"
                              >
                                {row?.gender || "N/A"}
                              </StyledTableCell>
                              <StyledTableCell
                                component="td"
                                scope="row"
                                className="common-25"
                              >
                                {row?.email || "N/A"}
                              </StyledTableCell>
                              <StyledTableCell
                                component="td"
                                scope="row"
                                className="common-25"
                              >
                                {(row?.phone && row?.country_code) ? `${row?.country_code.replace('+', '')} ${row?.phone}` : "N/A"}
                              </StyledTableCell>
                              {/* <StyledTableCell
                                align="left"
                                className="common-25"
                              ></StyledTableCell> */}
                            </StyledTableRow>
                          ))
                        ) : (
                          <StyledTableRow
                            key="1"
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell colSpan="5">
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100%",  // Ensures the Box takes full width of the TableCell
                                  height: "100%", // Ensures the Box takes full height of the TableCell
                                }}
                              >
                                No User Found
                              </Box>
                            </TableCell>
                          </StyledTableRow>
                        ))
                      }
                    </TableBody>
                    <TableFooter>
                      <CUPagination
                        numberOfPages={page.numberOfPages}
                        currentPage={page.currentPage}
                        onChange={handlePaginationChange}
                      />
                    </TableFooter>
                  </Table>
                </TableContainer>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ManageUserAccounts;
