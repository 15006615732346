
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PDFDownloader from "components/PdfDownloader";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

function Invoice({ date, id, price, status, noGutter, pdf }) {
  return (
    <MDBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <MDBox lineHeight={1.125}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {date}
        </MDTypography>
        <MDTypography variant="caption" fontWeight="regular" color="text">
          {id}
        </MDTypography>
          <MDBadge
            badgeContent={status}
            color={status === "success" ? "success" : (status === "pending" ? "pending" : "error")}
            // variant="gradient"
            size="sm"
          />
      </MDBox>
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="button" fontWeight="regular" color="text">
          {price}
        </MDTypography>
        <MDBox display="flex" alignItems="center" lineHeight={1} ml={3} sx={{ cursor: "pointer" }}>
          {/* <Icon fontSize="small">picture_as_pdf</Icon> */}
          {/* <MDTypography variant="button" fontWeight="bold">
            &nbsp;PDF */}
            { pdf ? <PDFDownloader
              pdfUrl={pdf}
              fileName={pdf ? pdf.split("/")[pdf.split("/").length - 1] : ""}
            /> : <MDBadge
                badgeContent={"N.A."}
                color={"error"}
                // variant="gradient"
                size="sm"
              /> }
          {/* </MDTypography> */}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Invoice
Invoice.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Invoice
Invoice.propTypes = {
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Invoice;
