// @mui material components
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import { GET_ORDERS_API_LIST } from "assets/api/orderManagementApi";
import CUPagination from "components/CUPagination";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Billing page components
import Bill from "layouts/billing/components/Bill";
import { useEffect, useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function BillingInformation() {
  const [ordersList, setordersList] = useState([]);
  const [page, setPage] = useState({
    currentPage: 1,
    dataShowLimit: 3,
    numberOfPages: 0,
  });

  async function getOrderData() {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    const token = userToken?.token;
    if (token) {
      const searchData = {
        page: page.currentPage,
        limit: page.dataShowLimit,
      };

      const ordersListData = await GET_ORDERS_API_LIST(token, searchData);
      if (ordersListData.status === 200) {
        setordersList(ordersListData.data);
        let totalCount = ordersListData.count;
        if (totalCount > 0) {
          let numberOfPages = Math.ceil(totalCount / page.dataShowLimit);
          setPage((p) => {
            return { ...page, numberOfPages: numberOfPages };
          });
        }
      } else {
        setordersList([]);
      }
    }
  }

  useEffect(() => {
    getOrderData();
  }, [page.currentPage]);

  const handlePaginationChange = (e, p) => {
    setPage({ ...page, currentPage: p });
  };

  return (
    <Card id="delete-account">
      <MDBox pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium">
          Billing Information
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {(ordersList && ordersList.length > 0) ?
            ordersList.map((data) => (
              <Bill
                name={data?.user?.first_name ? data?.user?.first_name + " " + data?.user?.last_name : `(${data?.user?.country_code}) ${data?.user?.phone}`}
                email={ data?.user?.email ? data?.user?.email : '-' }
                phone={`(${data?.user?.country_code}) ${data?.user?.phone}`}
                product={data?.product?.product_actual_name}
                user_billing_address={Object.values(data?.user_billing_address).filter(item => item !== null && item !== undefined && item !== 'undefined').join(", ")}
                user_shipping_address={Object.values(data?.user_shipping_address).filter(item => item !== null && item !== undefined && item !== 'undefined').join(", ")}
                order={`#${data?.order_id}`}
                date={data?.order_date}
              />
            )) : (
                  <StyledTableRow
                    key="1"
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell colSpan="9">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",  // Ensures the Box takes full width of the TableCell
                          height: "100%", // Ensures the Box takes full height of the TableCell
                        }}
                      >
                        No Billing Information Found
                      </Box>
                    </TableCell>
                  </StyledTableRow>
            )}
        </MDBox>
        <CUPagination
          numberOfPages={page.numberOfPages}
          currentPage={page.currentPage}
          onChange={handlePaginationChange}
        />
      </MDBox>
    </Card>
  );
}

export default BillingInformation;
