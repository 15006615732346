// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { GET_ORDERS_API_LIST } from "assets/api/orderManagementApi";
import CUPagination from "components/CUPagination";
import DateRangePickerComponent from "components/DateRange";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Billing page components
import Transaction from "layouts/billing/components/Transaction";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Transactions() {
  const [ordersList, setordersList] = useState([]);
  const [page, setPage] = useState({
    currentPage: 1,
    dataShowLimit: 8,
    numberOfPages: 0,
  });

  async function getCouponData() {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    const token = userToken?.token;
    if (token) {
      const searchData = {
        page: page.currentPage,
        limit: page.dataShowLimit,
      };

      const ordersListData = await GET_ORDERS_API_LIST(token, searchData);
      if (ordersListData.status === 200) {
        setordersList(ordersListData.data);
        let totalCount = ordersListData.count;
        if (totalCount > 0) {
          let numberOfPages = Math.ceil(totalCount / page.dataShowLimit);
          setPage((p) => {
            return { ...page, numberOfPages: numberOfPages };
          });
        }
      } else {
        setordersList([]);
      }
    }
  }

  useEffect(() => {
    getCouponData();
  }, [page.currentPage]);

  const handlePaginationChange = (e, p) => {
    setPage({ ...page, currentPage: p });
  };

  console.log("qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq", ordersList);
  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Your Transaction&apos;s
        </SoftTypography>
        {/* <SoftBox display="flex" alignItems="flex-start">
          <SoftBox color="text" mr={0.5} lineHeight={0}>
            <Icon color="inherit" fontSize="small">
              date_range
            </Icon>
          </SoftBox>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            23 - 30 March 2020 <DateRangePickerComponent />
          </SoftTypography>
        </SoftBox> */}
      </SoftBox>
      <SoftBox pt={3} pb={2} px={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {(ordersList && ordersList.length > 0) ?
            ordersList.map((data) => (
              <Transaction
                color={data?.order_status === "success" ? "success" : "dark"}
                icon={data?.order_status === "success" ? "arrow_upward" : "priority_high"}
                name={data?.payment_through}
                description={data?.order_date}
                value={data?.order_status === "success" ? `+ ${data?.currency} ${data?.final_price}` : `! ${data?.currency} ${data?.final_price}`}
                key={data?.order_id}
              />
            )) :(
              <StyledTableRow
                key="1"
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell colSpan="9">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",  // Ensures the Box takes full width of the TableCell
                      height: "100%", // Ensures the Box takes full height of the TableCell
                    }}
                  >
                    No Transaction Found
                  </Box>
                </TableCell>
              </StyledTableRow>
          )}
        </SoftBox>
        <CUPagination
          numberOfPages={page.numberOfPages}
          currentPage={page.currentPage}
          onChange={handlePaginationChange}
        />
      </SoftBox>
    </Card>
  );
}

export default Transactions;
