
// @mui material components
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";

import {
  Container,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import BasicLayout from "layouts/authentication/components/BasicLayout";

const handleSubmit = async (event) => {
  console.log(event);
  event.preventDefault();

};

const PrivacyPolicy = () => {
  return (
    <BasicLayout image={bgImage}>
      <Container style={{width: "100%", display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'flex-start'}}>
        {/* General Terms and Conditions */}
        <Card variant="outlined" style={{alignItems: 'center'}} sx={{ marginBottom: 2, width: '750px', textAlign: 'center' }}>
          <CardContent >
            <Typography variant="h4" gutterBottom>
              General terms and conditions for using the application
            </Typography>

            <Typography paragraph>
            This application is owned by  Unique Field Technology Company, officially registered in the Kingdom of Saudi Arabia. The application provides the service of requesting wall paintings with the sizes and specifications available in and specified by the customer.
            This agreement - the Terms and Conditions Agreement - is considered an official agreement between the application and the user customers who wish to benefit from the services available in it.
            Your use of the application requires that you have agreed to this agreement, and thus you acknowledge and agree that any use made by you or by your device, account, or number belonging to you of any of the services and products provided by the application is considered your acceptance of this agreement and its provisions, and accordingly You must not use the Application if you do not agree to this Agreement or anything contained in this Agreement.
            This agreement is subject to amendment and change by us, and we will notify any updated version of it in the application. The updates contained in the agreement will enter into force seven days after the date of notification, and after this period has passed, your continued use of the application will be deemed your consent to the new provisions included in these updates.
            </Typography>
          </CardContent>
        </Card>

        {/* General Special Terms and Conditions */}
        <Card variant="outlined" style={{width: '750px', alignItems: 'center'}} sx={{marginBottom: 8, textAlign: 'center'}}>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              General special terms and conditions of use
            </Typography>

            <List>
              <ListItem>
                <ListItemText>
                ● The customer must have legal and legal capacity, and must not be less than 18 years old if he is a natural person. Anyone who is under 18 years old or lacks legal capacity is obligated to use the application under the supervision of his guardian and under his responsibility.<br/>
                ● The customer must not be a competitor or provide services that compete with the services and products provided. If this clause is violated, the violator is obligated to compensate us for any exploitation of the application in any way.<br/>
                ● The customer acknowledges that his use of the application will be as stipulated in the following terms:<br/>
                ● He will not copy, download, reproduce, republish, frame, broadcast, or transmit in any way any of the content provided by the application except for normal personal, non-commercial uses only.<br/>
                ● The Customer is obligated not to do anything that would affect the operation of the application or cause unreasonable annoyance, abuse or disruption to any other users or our employees.<br/>
                ● The customer must not have previously been banned or prohibited from using the application.
                ● The customer must comply with all applicable laws and regulations within the country in which the service is provided.<br/>
                ● The customer must provide correct and accurate information to the application and must comply with any notifications made by the application regarding the services it provides to ensure that its operational operations are not hampered.<br/>
                ● The customer’s use of the application services must not be aimed at harming, harassing, or disturbing any person.<br/>
                ● The customer must maintain all contents and passwords to access his account securely.
                ● The application has the right to refuse to provide any service or use of the application without any compensation or justifying the reasons.<br/>
                ● The customer must refrain from using the application in any way that might lead to any other party using the application in a manner that violates, or is likely to violate, any existing and applicable laws, or the legal rights or entitlements of any other party, in accordance with all applicable laws.<br/>
                ● The customer is obligated to refrain from the following matters:<br/>
                ● Use an automated process to process, monitor, copy or extract any pages contained on the Application, or any information, content or data contained in or accessed by the Application, without our prior written consent.<br/>
                ● Use an automated process to aggregate or combine the information, content or data contained or accessible through the Application with information, material or data accessed or sourced from a third party.<br/>
                ● Use any automated process to interfere or attempt to interfere with the proper working of the Application.<br/>
                ● Take any action that imposes an unreasonable or disproportionately large load on the available infrastructure or application bandwidth.<br/>
                ● Reverse engineering, reverse compiling, disassembling, or doing any other work that would discover source code or other mathematical formulas or manipulations regarding computer software used in the infrastructure and operations related to the application.<br/>
                ● Copy, reproduce, alter, modify, make derivative works of or make public display of, any part of the App Content without our prior written consent.<br/>
                ● The e-mail address, phone number, and any other addresses belonging to the customer are considered an official means of communication and notifications, and through the notification, evidence is established that the customer has been notified.<br/>
                </ListItemText>
              </ListItem>

              {/* Add more ListItems for each point in the special terms and conditions */}
            </List>
          </CardContent>
        </Card>

        {/* Add more Card components and Typography components for the remaining content */}
      </Container>
    </BasicLayout>
  );
};

export default PrivacyPolicy;
