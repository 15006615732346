import * as React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import UploadFileIcon from "@mui/icons-material/UploadFile";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
// import { CREATE_COUPON_API } from "assets/api/couponManagementApi";
import validationMessage from "utils/validationMessage";
import { VIEW_SETTINGS_API, UPDATE_SETTINGS_API } from "assets/api/settingsApi";

export default function Settings() {  

  const defaultFormState = {
    '_id': "",
    'delivery_charge_per_product': "",
    'delivery_charge_per_product_error': false,
    'delivery_charge_per_product_error_message': "",
    'vat_percentage': "",
    'vat_percentage_error': false,
    'vat_percentage_error_message': "",
  };

  const [formData, setFormData] = useState(defaultFormState);

  // Get a settings details
  useEffect(() => {
    async function getSettingsData() {
     
        const tokenString = localStorage.getItem("token");
        const userToken = JSON.parse(tokenString);
        const token = userToken?.token;
        if (token) {
          const apiResponse = await VIEW_SETTINGS_API(token);
          if (apiResponse?.status === 200) {
            setFormData((values) => {
              if (
                apiResponse &&
                apiResponse.data &&
                apiResponse?.data?._id
              ) {
                values = {
                  ...values,
                  _id: apiResponse?.data?._id,
                };
              }
              if (
                apiResponse &&
                apiResponse.data &&
                apiResponse?.data?.delivery_charge_per_product
              ) {
                values = {
                  ...values,
                  delivery_charge_per_product: apiResponse?.data?.delivery_charge_per_product,
                  delivery_charge_per_product_error: false,
                  delivery_charge_per_product_error_message: "",
                };
              }
              if (
                apiResponse &&
                apiResponse.data &&
                apiResponse?.data?.vat_percentage
              ) {
                values = {
                  ...values,
                  vat_percentage: apiResponse?.data?.vat_percentage,
                  vat_percentage_error: false,
                  vat_percentage_error_message: "",
                };
              }
             
              return values;
            });
          } else {
            if (apiResponse && apiResponse.message) {
              toast.error(apiResponse.message);
            } else {
              toast.error(
                `${apiResponse?.errors[0]?.path.replaceAll("_", " ")} ${apiResponse?.errors[0]?.msg}`
              );
            }
          }
        } else {
          toast.error(validationMessage.PLEASE_LOGIN);
        }
      
    }
    getSettingsData();
  }, []);

  toast.configure({
    autoClose: 17000,
    draggable: true,
  });

  const handleFormDataChange = (e) => {
    //console.log(e.target);
    const name = e.target.name;
    const value = e.target.value;
    //console.log("FormData-->> ", formData);

    setFormData((values) => {
      if(name === 'delivery_charge_per_product') {
        values = { ...values, delivery_charge_per_product_error: false, delivery_charge_per_product_error_message: "" };
      }
      else if(name === 'vat_percentage') {
        values = { ...values, vat_percentage_error: false, vat_percentage_error_message: "" };
      }
      
      return { ...values, [name]: value };
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Form Validation
    if(formData.delivery_charge_per_product === "") {
      setFormData((values) => ({ ...values, delivery_charge_per_product_error: true, delivery_charge_per_product_error_message: validationMessage.DELIVERY_CHARGE_REQUIRED }))
    }
    if(formData.vat_percentage === "") {
      setFormData((values) => ({ ...values, vat_percentage_error: true, vat_percentage_error_message: validationMessage.VAT_PERCENTAGE_REQUIRED }))
    }
    
    if (formData._id && formData.delivery_charge_per_product && formData.vat_percentage) {
      // console.log("Enter Here --->> ***");
      // Save Data

      const data = {_id : formData._id, delivery_charge_per_product: formData.delivery_charge_per_product, vat_percentage: formData.vat_percentage}
      //console.log(data);
      const tokenString = localStorage.getItem("token");
      const userToken = JSON.parse(tokenString);
      const token = userToken?.token;
      if (token) {
        // console.log("IF PART ->>");
        const apiResponse = await UPDATE_SETTINGS_API(token, data);
        console.log("apiResponse-->> ", apiResponse);
        if(apiResponse?.status === 200) {
          toast.success(apiResponse.message);
        } else {
          if(apiResponse && apiResponse.message) {
            toast.error(apiResponse.message);
          } else {
              toast.error(`${apiResponse?.errors[0]?.msg}`);
        
          }
        }
      } else {
        toast.error(validationMessage.PLEASE_LOGIN);
      }
    }
  }

  return (
    <React.Fragment>
      <Paper elevation={3} sx={{ marginRight: "15%", marginLeft: "15%" }}>
        <Box sx={{ padding: 5 }}>
          {/* <Typography variant="h3" align="center" gutterBottom sx={{ paddingBottom: 5 }}>
            Create Coupon
          </Typography> */}
          <form onSubmit={handleSubmit} >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <InputLabel
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    fontWeight: 700,
                  }}
                >
                  Delivery Charge Per Product
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  error={formData.delivery_charge_per_product_error ? true : false}
                  helperText={formData.delivery_charge_per_product_error_message ? formData.name_en_error_message : ""}
                  id={formData.delivery_charge_per_product_error ? "outlined-error-helper-text" : "delivery_charge_per_product"}
                  name="delivery_charge_per_product"
                  label="Delivery Charge Per Product"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  onChange={handleFormDataChange}
                  value={formData.delivery_charge_per_product}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <InputLabel
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    fontWeight: 700,
                  }}
                >
                  VAT Percentage
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  error={formData.vat_percentage_error ? true : false}
                  helperText={formData.vat_percentage_error_message ? formData.name_ar_error_message : ""}
                  id={formData.vat_percentage_error ? "outlined-error-helper-text" : "vat_percentage"}
                  name="vat_percentage"
                  label="VAT Percentage"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  onChange={handleFormDataChange}
                  value={formData.vat_percentage}
                />
              </Grid>

              


              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={5} />
              <Grid item xs={12} sm={12}>
                <Button type="submit" variant="contained" sx={{ color: "yellow" }}>
                  Update
                </Button>
              </Grid>
              <Grid item xs={12} sm={5} />
            </Grid>
          </form>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
