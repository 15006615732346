import React from 'react';
import { Icon, Tooltip } from '@mui/material';
import MDButton from 'components/MDButton';

const ImageDownloader = ({ imageUrl, fileName }) => {
    const handleDownload = async () => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName || 'downloaded-image.jpg';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url); // Clean up the URL object
        } catch (error) {
            console.error('Failed to download image:', error);
        }
    };

  return (
    <Tooltip title={"Download the ordered picture."}>
        <MDButton color={"dark"} onClick={handleDownload} size={"small"} iconOnly><Icon>download</Icon></MDButton>
    </Tooltip>
  );
};

export default ImageDownloader;
