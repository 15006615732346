import { API_CUSTOM_FRAME_SIZE_LIST, API_CUSTOM_FRAME_SIZE_ADD, actualApiUrl, API_CUSTOM_FRAME_SIZE_VIEW, API_CUSTOM_FRAME_SIZE_DELETE, API_CUSTOM_FRAME_SIZE_UPDATE } from "./commonApiEndPoint";
import { COMMON_HEADERS } from "./commonApiHeader";

// get api
export const GET_CUSTOM_FRAME_SIZE_API = async ({ page, limit }) => {
    try{
        const getProductDetails = await fetch(`${actualApiUrl(API_CUSTOM_FRAME_SIZE_LIST)}?page=${page}&limit=${limit}`, {
            method: "GET",
            headers: COMMON_HEADERS
        });
        const returnData = await getProductDetails.json();
        // console.log("returnData->>> ", returnData);

        return returnData;
    } catch(error) {
        return error;
    }
}
// create api
export const CREATE_CUSTOM_FRAME_SIZE_API = async (token, payload) => {
    try{
        // delete COMMON_HEADERS["Content-Type"];
        console.log("COMMON_HEADERS--->> ", COMMON_HEADERS);
        const headers = { ...COMMON_HEADERS, 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' };
        const addProduct = await fetch(actualApiUrl(API_CUSTOM_FRAME_SIZE_ADD), {
            method: "POST",
            headers: headers,
            body: payload
        });
        const returnData = await addProduct.json();
        // console.log("returnData->>> ", returnData);

        return returnData;
    } catch(error) {
        console.log("Error->> ", error);
        return error;
    }
}
// view api
export const VIEW_CUSTOM_FRAME_SIZE_API = async (token, customFrameId) => {
    try{
        const headers = { ...COMMON_HEADERS, 'Authorization': `Bearer ${token}` };
        const getProductDetails = await fetch(`${actualApiUrl(API_CUSTOM_FRAME_SIZE_VIEW).replace('{id}', `${customFrameId}`)}`, {
            method: "GET",
            headers: headers
        });
        const returnData = await getProductDetails.json();

        return returnData;
    } catch(error) {
        return error;
    }
}
// update api
export const UPDATE_CUSTOM_FRAME_SIZE_API = async (token, customFrameId, payload) => {
    try{
        // delete COMMON_HEADERS["Content-Type"];
        // console.log("COMMON_HEADERS--->> ", COMMON_HEADERS);
        const headers = { ...COMMON_HEADERS, 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' };
        const updateProduct = await fetch(`${actualApiUrl(API_CUSTOM_FRAME_SIZE_UPDATE).replace('{id}', `${customFrameId}`)}`, {
            method: "PUT",
            headers: headers,
            body: payload
        });
        const returnData = await updateProduct.json();
        // console.log("returnData->>> ", returnData);

        return returnData;
    } catch(error) {
        console.log("Error->> ", error);
        return error;
    }
}
// delete api
export const DELETE_CUSTOM_FRAME_SIZE_API = async (token, productId) => {
    try{
        // console.log("COMMON_HEADERS--->> ", COMMON_HEADERS);
        const headers = { ...COMMON_HEADERS, 'Authorization': `Bearer ${token}` };
        const updateProduct = await fetch(`${actualApiUrl(API_CUSTOM_FRAME_SIZE_DELETE).replace('{id}', `${productId}`)}`, {
            method: "DELETE",
            headers: headers,
        });
        const returnData = await updateProduct.json();
        // console.log("returnData->>> ", returnData);

        return returnData;
    } catch(error) {
        console.log("Error->> ", error);
        return error;
    }
}