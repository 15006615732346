import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { VIEW_ORDERS_VIEW_API, EXPORT_ORDER_UPDATE_STATUS_API } from "assets/api/orderManagementApi";
import PDFDownloader from "components/PdfDownloader";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";

const OrderDetailsModal = ({ open, onClose, orderDetails }) => {
  const [data, setData] = useState(null);
  const [openSelect, setOpenSelect] = useState(false);
  const [orderStatusData, setOrderStatusData] = useState(null);

  const handleSelectClose = () => {
    setOpenSelect(false);
  };

  const handleSelectOpen = () => {
    setOpenSelect(true);
  };

 

  const handleDropDownDataChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;  
   // console.log(name, value);

      if (value) {
        const tokenString = localStorage.getItem("token");
        const userToken = JSON.parse(tokenString);
        const token = userToken?.token;
        const orderData = {_id: orderDetails, [name]: value  };
        if (token) {
          try {
            const apiResponse = await EXPORT_ORDER_UPDATE_STATUS_API(token, orderData);
            
            if (apiResponse?.status === 200) {
              setOrderStatusData(value);
              
            } else {
              //toast.error("An error occurred. Please try again later.");
              toast.error(apiResponse.message);
            }
          } catch (error) {
            // Handle errors that occur during the API call
            console.error("An error occurred:", error);
            // toast.error("An error occurred. Please try again later.");
          }
        }
      }
  };


  

  // create a download function for pdf:
  const onDownload = () => {
    console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww");
    const link = document.createElement("a");
    link.download = `download.txt`;
    link.href = "./download.txt";
    link.click();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (orderDetails) {
        const tokenString = localStorage.getItem("token");
        const userToken = JSON.parse(tokenString);
        const token = userToken?.token;
        if (token) {
          try {
            const apiResponse = await VIEW_ORDERS_VIEW_API(token, orderDetails);
            if (apiResponse?.status === 200) {
              setData(apiResponse?.data);
              setOrderStatusData(!apiResponse?.data?.order_progress_status ? 'new' : apiResponse?.data?.order_progress_status);
              // Handle success, e.g., show a success message
              // toast.success(apiResponse.message);
              // Perform any other necessary actions
            } else {
              // Handle API errors, e.g., show an error message
              // toast.error(apiResponse.error);
            }
          } catch (error) {
            // Handle errors that occur during the API call
            console.error("An error occurred:", error);
            // toast.error("An error occurred. Please try again later.");
          }
        }
      }
    };

    fetchData(); // Call the function to fetch data
  }, [orderDetails]);

  console.log("sssssssssssssssssssssssssssssssss", data);
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="order-details-modal">
      <Box className="modal-container">
        <Typography variant="h6" component="h2">
          Order Details
        </Typography>
        <div className="order-details-content">
          {/* Display order details */}
          <Typography variant="body1">
            <b>Product Name:</b> {data?.product?.product_actual_name}
          </Typography>
          <Typography variant="body2">
            <b>Order ID:</b> {data?.order_id}
          </Typography>
          <Typography variant="body2">
            <b>Order Date:</b> {data?.order_date}
          </Typography>
          <Typography variant="body2">
            <b>Product Height In CM:</b> {data?.product_height_cm}
          </Typography>
          <Typography variant="body2">
            <b>Product Width In CM:</b> {data?.product_width_cm}
          </Typography>
          <Typography variant="body2">
            <b>Product Quantity:</b> {data?.quantity}
          </Typography>
          <Typography variant="body2">
            <b>Product Price Per Centimeter Square:</b>{" "}
            {data?.currency} {data?.product?.product_price_per_centimeter_square}
          </Typography>
          <Typography variant="body2">
            <b>Original Cost:</b>{" "}
            {data?.currency} {data?.original_product_price}
          </Typography>
          <Typography variant="body2">
            <b>Coupon Discount:</b>{" "}
            {data?.currency} {data?.total_coupon_discount}
          </Typography>
          <Typography variant="body2">
            <b>Discounted Cost:</b>{" "}
            {data?.currency} {data?.discounted_product_price}
          </Typography>
          <Typography variant="body2">
            <b>Tax:</b>{" "}
            {data?.currency} {data?.total_tax}
          </Typography>
          <Typography variant="body2">
            <b>Delivery Charge:</b>{" "}
            {data?.currency} {data?.total_delivery_charge}
          </Typography>
          <Typography variant="body2">
            <b>Total Cost:</b> {data?.currency} {data?.final_price}
          </Typography>
          <Typography variant="body2"><b>Payment:</b><MDBadge
                badgeContent={(data?.payment_type && data.payment_type.length > 0) ? data.payment_type : "N/A"}
                color={data?.payment_type === "online" ? "success" : "error"}
                // variant="gradient"
                size="sm"
              /></Typography>
          <Typography variant="body2">
            <b>User Name:</b> { data?.user?.first_name ? `${data?.user?.first_name} ${data?.user?.last_name}` : "-" }
          </Typography>
          <Typography variant="body2">
            <b>User Email:</b> { data?.user?.email ? data?.user?.email : "-" }
          </Typography>
          <Typography variant="body2">
            <b>User Phone:</b> ({data?.user?.country_code}) {data?.user?.phone}
          </Typography>
          <Typography variant="body2">
            <b>User Billing Address</b> {data?.user_billing_address ? Object.values(data?.user_billing_address).filter(item => item !== null && item !== undefined && item !== 'undefined' ).join(", ") : '-'}
          </Typography>
          <Typography variant="body2">
            <b>User Shipping Address</b> {data?.user_shipping_address ? Object.values(data?.user_shipping_address).filter(item => item !== null && item !== undefined && item !== 'undefined' ).join(", ") : '-'}
          </Typography>
          <Typography variant="body2"><b>Payment Status:</b>
              <MDBadge
                badgeContent={data?.order_status}
                color={data?.order_status === "success" ? "success" : (data?.order_status === "pending" ? "pending" : "error")}
                // variant="gradient"
                size="sm"
              />
          </Typography>
          <Typography variant="body2"><b>Order Status:</b>
                  <Select
                    // error={formData.gender_error ? true : false}
                    // helperText={formData.gender_error_message ? formData.gender_error_message : ""}
                    // id={formData.gender_error ? "outlined-error-helper-text" : "demo-controlled-open-select"}
                    // labelId="demo-controlled-open-select-label"
                    name="order_progress_status"
                    open={openSelect}
                    onClose={handleSelectClose}
                    onOpen={handleSelectOpen}
                    label="Discount"
                    style={{minHeight: "35px", marginLeft: "9px"}}
                    onChange={handleDropDownDataChange}
                    value={orderStatusData}
                  >
                    <MenuItem value="new">New</MenuItem>
                    <MenuItem value="processing">Processing</MenuItem>
                    <MenuItem value="completed">Completed</MenuItem>
                  </Select>

          </Typography>
          <Typography variant="body2">
            <b>Download Order PDF:</b>{" "}
            { data?.order_confirmation_pdf ? <PDFDownloader
              pdfUrl={data?.order_confirmation_pdf}
              fileName={data?.order_confirmation_pdf.split("/")[data?.order_confirmation_pdf.split("/").length - 1]}
            /> : <MDBadge
                badgeContent={"Not Completed"}
                color={"error"}
                // variant="gradient"
                size="sm"
              />}
            {/* <Button onClick={onDownload} variant="contained" color="success">
              Download Pdf
            </Button> */}
          </Typography>
        </div>
        <Button onClick={onClose} className="close-button">
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default OrderDetailsModal;
