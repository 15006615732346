import {
  actualApiUrl,
  API_SETTINGS
} from "./commonApiEndPoint";
import { COMMON_HEADERS } from "./commonApiHeader";


export const VIEW_SETTINGS_API = async (token) => {
  try {
    const headers = { ...COMMON_HEADERS, Authorization: `Bearer ${token}` };
    const getSettingsViews = await fetch(
      `${actualApiUrl(API_SETTINGS)}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const returnData = await getSettingsViews.json();
    return returnData;
  } catch (error) {
    return error;
  }
};

export const UPDATE_SETTINGS_API = async (token, payload) => {
  try {
    delete COMMON_HEADERS["Content-Type"];
     //console.log("payload--->> ", payload);
    const headers = { ...COMMON_HEADERS, Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
    const updateSettings = await fetch(
      `${actualApiUrl(API_SETTINGS)}`,
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(payload),
      }
    );
    const returnData = await updateSettings.json();

    return returnData;
  } catch (error) {
    return error;
  }
};

