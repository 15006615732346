import {
  ADMIN_DASHBOARD_DATA,
  API_ADMIN_CHANGE_PASSWORD,
  API_ADMIN_CREATE,
  API_ADMIN_DELETE,
  API_ADMIN_LIST,
  API_ADMIN_LOGIN,
  API_ADMIN_LOGOUT,
  API_ADMIN_UPDATE,
  API_ADMIN_VIEW,
  API_USER_LIST,
  API_USER_EXPORT,
  actualApiUrl,
} from "./commonApiEndPoint";
import { COMMON_HEADERS } from "./commonApiHeader";

// Login
export const ADMIN_LOGIN = async (payload) => {
  try {
    const adminLoginDetails = await fetch(actualApiUrl(API_ADMIN_LOGIN), {
      method: "POST",
      headers: COMMON_HEADERS,
      body: JSON.stringify(payload),
    });
    const returnData = await adminLoginDetails.json();
    console.log("returnData->>> ", returnData);

    return returnData;
  } catch (error) {
    return error;
  }
};

// Logout
export const ADMIN_LOGOUT = async (token) => {
  try {
    const headers = { ...COMMON_HEADERS, Authorization: `Bearer ${token}` };
    const adminLogoutDetails = await fetch(actualApiUrl(API_ADMIN_LOGOUT), {
      method: "PUT",
      headers: headers,
    });
    const returnData = await adminLogoutDetails.json();

    return returnData;
  } catch (error) {
    return error;
  }
};

// GET API Admin List

// get api
export const GET_ADMIN_LIST = async (token, { page, limit }) => {
  try {
    const headers = { ...COMMON_HEADERS, Authorization: `Bearer ${token}` };
    const getAdminDetails = await fetch(
      `${actualApiUrl(API_ADMIN_LIST)}?page=${page}&limit=${limit}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const returnData = await getAdminDetails.json();
    return returnData;
  } catch (error) {
    return error;
  }
};

// get api
export const GET_USER_LIST = async (token, { page, limit }) => {
  try {
    const headers = { ...COMMON_HEADERS, Authorization: `Bearer ${token}` };
    const getAdminDetails = await fetch(
      `${actualApiUrl(API_USER_LIST)}?page=${page}&limit=${limit}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const returnData = await getAdminDetails.json();
    return returnData;
  } catch (error) {
    return error;
  }
};

// view api
export const VIEW_ADMIN_API = async (token, productId) => {
  try {
    const headers = { ...COMMON_HEADERS, Authorization: `Bearer ${token}` };
    const getAdminViews = await fetch(
      `${actualApiUrl(API_ADMIN_VIEW)}/${productId}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const returnData = await getAdminViews.json();
    return returnData;
  } catch (error) {
    return error;
  }
};

// create api
export const CREATE_ADMIN_API = async (token, payload) => {
  try {
    delete COMMON_HEADERS["Content-Type"];
    const headers = { ...COMMON_HEADERS, Authorization: `Bearer ${token}` };
    const addAdminPasswordChange = await fetch(actualApiUrl(API_ADMIN_CREATE), {
      method: "POST",
      headers: headers,
      body: payload,
    });
    const returnData = await addAdminPasswordChange.json();
    return returnData;
  } catch (error) {
    console.log("Error->> ", error);
    return error;
  }
};

// update api
export const UPDATE_ADMIN_API = async (token, adminId, payload) => {
  try {
    delete COMMON_HEADERS["Content-Type"];
    // console.log("COMMON_HEADERS--->> ", COMMON_HEADERS);
    const headers = { ...COMMON_HEADERS, Authorization: `Bearer ${token}` };
    const updateAdmin = await fetch(
      `${actualApiUrl(API_ADMIN_UPDATE)}/${adminId}`,
      {
        method: "PUT",
        headers: headers,
        body: payload,
      }
    );
    const returnData = await updateAdmin.json();

    return returnData;
  } catch (error) {
    return error;
  }
};
// delete api
export const DELETE_ADMIN_API = async (token, adminId) => {
  try {
    const headers = { ...COMMON_HEADERS, Authorization: `Bearer ${token}` };
    const deleteAdmin = await fetch(
      `${actualApiUrl(API_ADMIN_DELETE)}/${adminId}`,
      {
        method: "DELETE",
        headers: headers,
      }
    );
    const returnData = await deleteAdmin.json();
    return returnData;
  } catch (error) {
    return error;
  }
};

// update Admin Password

export const ADMIN_PASSWORD_CHANGE = async (token) => {
  try {
    // delete COMMON_HEADERS["Content-Type"];
    const headers = {
      ...COMMON_HEADERS,
      Authorization: `Bearer ${token?.token}`,
      'Content-Type': 'application/json'
    };
    const addAdminPasswordChange = await fetch(
      actualApiUrl(API_ADMIN_CHANGE_PASSWORD),
      {
        method: "PUT",
        headers: headers,
        body: JSON.stringify(token?.payload),
      }
    );
    const returnData = await addAdminPasswordChange.json();
    return returnData;
  } catch (error) {
    return error;
  }
};

// get admin list

export const GET_DASHBOARD_LIST = async (token) => {
  try {
    const headers = { ...COMMON_HEADERS, Authorization: `Bearer ${token}` };
    const getAdminDetailsList = await fetch(
      `${actualApiUrl(ADMIN_DASHBOARD_DATA)}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const returnData = await getAdminDetailsList.json();
    return returnData;
  } catch (error) {
    return error;
  }
};


// get api
export const GET_USER_EXPORT = async (token) => {
  try {
    const headers = { ...COMMON_HEADERS, Authorization: `Bearer ${token}` };
    const getUserDetails = await fetch(
      `${actualApiUrl(API_USER_EXPORT)}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    const blob = await getUserDetails.blob();
    const url = URL.createObjectURL(blob);
    window.open(url);
    // return returnData;
  } catch (error) {
    return error;
  }
};
