// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

// Billing page components
import Invoice from "layouts/billing/components/Invoice";
import { GET_ORDERS_API_LIST } from "assets/api/orderManagementApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Invoices() {
  const [ordersList, setordersList] = useState([]);
  const [page, setPage] = useState({
    currentPage: 1,
    dataShowLimit: 10,
    numberOfPages: 0,
  });

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/Order-Management'); // replace with your target route
  };


  async function getCouponData() {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    const token = userToken?.token;
    if (token) {
      const searchData = {
        page: page.currentPage,
        limit: page.dataShowLimit,
      };

      const ordersListData = await GET_ORDERS_API_LIST(token, searchData);
      if (ordersListData.status === 200) {
        setordersList(ordersListData.data);
        let totalCount = ordersListData.count;
        if (totalCount > 0) {
          let numberOfPages = Math.ceil(totalCount / page.dataShowLimit);
          setPage((p) => {
            return { ...page, numberOfPages: numberOfPages };
          });
        }
      } else {
        setordersList([]);
      }
    }
  }

  useEffect(() => {
    getCouponData();
  }, [page.currentPage]);

  // console.log(
  //   "ssssssssssssssssssssssssssssssssssssssssssssssssssssssssss",
  //   ordersList
  // );
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDTypography variant="h6" fontWeight="medium">
          Invoices
        </MDTypography>
        {/* <MDButton variant="outlined" color="info" size="small">
          view all
        </MDButton> */}
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {(ordersList && ordersList.length > 0) ?
            ordersList.map((data, index) => {
              if(index >= 3) return false;
              return <Invoice
                date={data?.order_date}
                id={`#${data?.order_id}`}
                status={data?.order_status}
                price={ data?.currency + " " + data?.final_price }
                pdf={data?.order_confirmation_pdf}
                noGutter
                key={data?.order_id}
              />
          }) : (
                  <StyledTableRow
                    key="1"
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell colSpan="9">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",  // Ensures the Box takes full width of the TableCell
                          height: "100%", // Ensures the Box takes full height of the TableCell
                        }}
                      >
                        No Invoice Found
                      </Box>
                    </TableCell>
                  </StyledTableRow>
          )}
        </MDBox>
        { ordersList && ordersList.length > 3 && <MDButton variant="gradient" color="info" fullWidth onClick={handleButtonClick}>View All Orders</MDButton> }
      </MDBox>
    </Card>
  );
}

export default Invoices;
