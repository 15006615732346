import * as React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import validationMessage from "utils/validationMessage";
import { toast } from "react-toastify";
import { VIEW_CUSTOM_FRAME_SIZE_API } from "assets/api/customFrameSizeManagementApi";
import { UPDATE_CUSTOM_FRAME_SIZE_API } from "assets/api/customFrameSizeManagementApi";

export default function UserForm() {
  toast.configure({
    autoClose: 17000,
    draggable: true,
  });

  const navigate = useNavigate();
  const params = useParams();

  const defaultFormState = {
    name_en: "",
    name_en_error: false,
    name_en_error_message: "",
    width_cm: "",
    width_cm_error: false,
    width_cm_error_message: "",
    height_cm: "",
    height_cm_error: false,
    height_cm_error_message: "",
  };

  const [formData, setFormData] = useState(defaultFormState);

  useEffect(() => {
    // console.log("ID-->> ", searchParams.get("id"));
    async function getCustomFrameData() {
      if (params.id) {
        const tokenString = localStorage.getItem("token");
        const userToken = JSON.parse(tokenString);
        const token = userToken?.token;
        if (token) {
          const apiResponse = await VIEW_CUSTOM_FRAME_SIZE_API(token, params.id);
          if (apiResponse?.status === 200) {
            setFormData((values) => {
              if (
                apiResponse &&
                apiResponse?.data &&
                apiResponse?.data?.name
              ) {
                values = {
                  ...values,
                  name_en: apiResponse?.data?.name,
                  name_en_error: false,
                  name_en_error_message: "",
                };
              }
              if (
                apiResponse &&
                apiResponse?.data &&
                apiResponse?.data?.width_cm
              ) {
                values = {
                  ...values,
                  width_cm: apiResponse?.data?.width_cm,
                  width_cm_error: false,
                  width_cm_error_message: "",
                };
              }
              if (
                apiResponse &&
                apiResponse?.data &&
                apiResponse?.data?.width_cm
              ) {
                values = {
                  ...values,
                  height_cm: apiResponse?.data?.height_cm,
                  height_cm_error: false,
                  height_cm_error_message: "",
                };
              }

              return values;
            });
          } else {
            if (apiResponse && apiResponse?.message) {
              toast.error(apiResponse?.message);
            } else {
              toast.error(
                `${apiResponse?.errors[0]?.path.replaceAll("_", " ")} ${apiResponse?.errors[0]?.msg}`
              );
            }
          }
        } else {
          toast.error(validationMessage.PLEASE_LOGIN);
        }
      }
    }
    getCustomFrameData();
  }, [params.id]);

  const handleFormDataChange = (e) => {
    // console.log(e.target.files[0]);
    const name = e.target.name;
    const value = e.target.value;

    setFormData((values) => {
      if (name === "name_en") {
        values = { ...values, name_en_error: false, name_en_error_message: "" };
      } else if (name === "width_cm") {
        values = { ...values, width_cm_error: false, width_cm_error_message: "" };
      } else if (name === "height_cm") {
        values = { ...values, height_cm_error: false, height_cm_error_message: "" };
      }
      return { ...values, [name]: value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Form Validation
    if (formData.name_en == "") {
      setFormData((values) => ({
        ...values,
        name_en_error: true,
        name_en_error_message: validationMessage.CUSTOM_FRAME_NAME_REQUIRED,
      }));
    }
    if (formData.width_cm == "") {
      setFormData((values) => ({
        ...values,
        width_cm_error: true,
        width_cm_error_message: validationMessage.WIDTH_REQUIRED,
      }));
    }
    if (formData.height_cm == "") {
      setFormData((values) => ({
        ...values,
        height_cm_error: true,
        height_cm_error_message: validationMessage.HEIGHT_REQUIRED,
      }));
    }
    if (
      formData.name_en &&
      formData.width_cm &&
      formData.height_cm
    ) {
      let frameDataObj = {
        "name": formData.name_en,
        "width_cm": formData.width_cm,
        "height_cm": formData.height_cm
      };
      frameDataObj = JSON.stringify(frameDataObj)

      const tokenString = localStorage.getItem("token");
      const userToken = JSON.parse(tokenString);
      const token = userToken?.token;
      if (token) {
        const apiResponse = await UPDATE_CUSTOM_FRAME_SIZE_API(token, params.id, frameDataObj);
        if (apiResponse?.status === 200) {
          toast.success(apiResponse.message);
          navigate("/custom-frame-management");
          // window.location.reload(false);
        } else {
          if (apiResponse && apiResponse.message) {
            toast.error(apiResponse.message);
          } else {
            // toast.error(
            //   `${apiResponse?.errors[0]?.path.replaceAll("_", " ")} ${apiResponse?.errors[0]?.msg}`
            // );
            if(apiResponse?.errors[0]?.path == 'width_cm' || apiResponse?.errors[0]?.path == 'height_cm') {
              toast.error(
                `${apiResponse?.errors[0]?.msg}`
              );
            } else {
              toast.error(
                `${apiResponse?.errors[0]?.path.replaceAll("_", " ")} ${apiResponse?.errors[0]?.msg}`
              );
            }
          }
        }
      } else {
        toast.error(validationMessage.PLEASE_LOGIN);
      }
    }
  };

  return (
    <React.Fragment>
      <Paper elevation={3} sx={{ marginRight: "15%", marginLeft: "15%" }}>
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" gutterBottom sx={{ paddingBottom: 5 }}>
            Create Custom Frame
          </Typography>
          <form onSubmit={handleSubmit} enctype="multipart/form-data">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3.5}>
                <InputLabel
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    fontWeight: 700,
                  }}
                >
                  Custom Frame Name
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8.5}>
                <TextField
                  error={formData.name_en_error ? true : false}
                  helperText={
                    formData.name_en_error_message
                      ? formData.name_en_error_message
                      : ""
                  }
                  id={
                    formData.name_en_error
                      ? "outlined-error-helper-text"
                      : "name_en"
                  }
                  name="name_en"
                  label="Custom Frame Name"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  onChange={handleFormDataChange}
                  value={formData.name_en}
                />
              </Grid>

              <Grid item xs={12} sm={3.5}>
                <InputLabel
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    fontWeight: 700,
                  }}
                >
                  Width (CM)
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8.5}>
                <TextField
                  error={formData.width_cm_error ? true : false}
                  helperText={
                    formData.width_cm_error_message
                      ? formData.width_cm_error_message
                      : ""
                  }
                  id={
                    formData.width_cm_error
                      ? "outlined-error-helper-text"
                      : "width_cm"
                  }
                  name="width_cm"
                  label="Width In CM"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  type="number"
                  onChange={handleFormDataChange}
                  value={formData.width_cm}
                />
              </Grid>

              <Grid item xs={12} sm={3.5}>
                <InputLabel
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    fontWeight: 700,
                  }}
                >
                  Height (CM)
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8.5}>
                <TextField
                  error={formData.height_cm_error ? true : false}
                  helperText={
                    formData.height_cm_error_message
                      ? formData.height_cm_error_message
                      : ""
                  }
                  id={
                    formData.height_cm_error
                      ? "outlined-error-helper-text"
                      : "height_cm"
                  }
                  name="height_cm"
                  label="Height In CM"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  type="number"
                  onChange={handleFormDataChange}
                  value={formData.height_cm}
                />
              </Grid>

              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={5} />
              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  sx={{ color: "yellow", width: "272px", margin: "5px" }}
                  onClick={() => navigate(-1)}
                >
                  Go Back To The Custom Frame
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ color: "yellow" }}
                >
                  Update
                </Button>
              </Grid>
              <Grid item xs={12} sm={5} />
            </Grid>
          </form>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
