// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { Box, Card, CircularProgress, Icon, TableFooter, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import Bill from "layouts/billing/components/Bill";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

//Table code  import:
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MDButton from "components/MDButton";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useMaterialUIController } from "context";
import { useEffect, useState } from "react";
import {
  GET_COUPON_API,
  DELETE_COUPON_API,
} from "assets/api/couponManagementApi";
import validationMessage from "utils/validationMessage";
import moment from "moment";
import CUDialog from "components/CUDialog";
import CUPagination from "components/CUPagination";
import { GET_ORDERS_API_LIST } from "assets/api/orderManagementApi";
import { VIEW_ORDERS_VIEW_API } from "assets/api/orderManagementApi";
import OrderDetailsModal from "utils/OrderDetailsModal";
import MDBadge from "components/MDBadge";
import ImageDownloader from "components/ImageDownloader";
import { EXPORT_ORDERS_LIST_API } from "assets/api/orderManagementApi";
import OrderStatusModal from "utils/OrderStatusModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function OrdersManagements() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const { sales, tasks } = reportsLineChartData;

  const navigate = useNavigate();
  toast.configure({
    autoClose: 17000,
    draggable: true,
  });

  const [apiCalled, setApiCalled] = useState(false);
  const [ordersList, setordersList] = useState([]);
  const [page, setPage] = useState({
    currentPage: 1,
    dataShowLimit: 10,
    numberOfPages: 0,
  });

  async function getCouponData() {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    const token = userToken?.token;
    if (token) {
      const searchData = {
        page: page.currentPage,
        limit: page.dataShowLimit,
      };

      const ordersListData = await GET_ORDERS_API_LIST(token, searchData);
      if (ordersListData.status === 200) {
        setordersList(ordersListData.data);
        // setordersList([]);
        let totalCount = ordersListData.count;
        if (totalCount > 0) {
          let numberOfPages = Math.ceil(totalCount / page.dataShowLimit);
          setPage((p) => {
            return { ...page, numberOfPages: numberOfPages };
          });
        }
        setApiCalled(true);
      } else {
        setordersList([]);
        setApiCalled(true);
      }
    } else {
      toast.error(validationMessage.PLEASE_LOGIN);
      setApiCalled(true);
    }
  }

  useEffect(() => {
    setApiCalled(false);
    getCouponData();
  }, [page.currentPage]);

  const handlePaginationChange = (e, p) => {
    setPage({ ...page, currentPage: p });
  };

  // Handle Download
  const handleDownload = async () => {
    const tokenString = localStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    const token = userToken?.token;
    await EXPORT_ORDERS_LIST_API(token);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [deleteCoupon, setDeleteCoupon] = useState();
  const handleClickDialogOpen = (orderId) => {
    setDeleteCoupon(orderId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(""); // Store the order details here

  const handleOpenModal = (orderDetails) => {
    setSelectedOrder(orderDetails);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedOrder("");

    setApiCalled(false);
    getCouponData();
  };

  const [modalOrderStatusOpen, setOrderStatusModalOpen] = useState(false);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");

  const handleOrderStatusOpenModal = (orderDetails) => {
    setSelectedOrderStatus(orderDetails);
    setOrderStatusModalOpen(true);
  };

  const handleOrderStatusCloseModal = () => {
    setOrderStatusModalOpen(false);
    setSelectedOrderStatus("");

    setApiCalled(false);
    getCouponData();
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  pt={2}
                  px={2}
                  display="flex"
                  variant="gradient"
                  borderRadius="lg"
                  coloredShadow="info"
                  bgColor="info"
                  justifyContent="space-between"
                  alignItems="center"
                  paddingBottom="15px"
                >
                  <MDTypography variant="h6" color="white">
                    Orders List
                  </MDTypography>
                  <MDButton variant="gradient" color={"dark"} size={"small"} onClick={handleDownload}><Icon>download</Icon>&nbsp;Export</MDButton>
                </MDBox>

                <MDBox pt={3}>
                  {/* <Grid container spacing={3}></Grid> */}
                  {/* <MDBox> */}
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 450 }} aria-label="simple table">
                        <TableHead>
                          {/* <h5 className="refferal">Coupon Management</h5> */}
                          <StyledTableRow sx={{ minWidth: 450 }}>
                            <StyledTableCell>Order Id</StyledTableCell>
                            <StyledTableCell>Product Name</StyledTableCell>
                            <StyledTableCell>Product Price</StyledTableCell>
                            <StyledTableCell>Frame Image</StyledTableCell>
                            <StyledTableCell>Chosen Picture</StyledTableCell>
                            <StyledTableCell>Actual Image</StyledTableCell>
                            <StyledTableCell>Quantity</StyledTableCell>
                            <StyledTableCell>Order Status</StyledTableCell>
                            <StyledTableCell align="center">Actions</StyledTableCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {!apiCalled && 
                            <StyledTableRow>
                              <TableCell colSpan="9">
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",  // Ensures the Box takes full width of the TableCell
                                    height: "100%", // Ensures the Box takes full height of the TableCell
                                  }}
                                >
                                  <CircularProgress />
                                </Box>
                              </TableCell>
                            </StyledTableRow>
                          }
                          {apiCalled && ((ordersList && ordersList.length > 0) ? (
                              ordersList.map((row) => (
                                <StyledTableRow
                                  key={row._id}
                                  sx={{
                                    "&:last-child td, &:last-child th": { border: 0 },
                                  }}
                                >
                                  <StyledTableCell
                                    component="td"
                                    scope="row"
                                    className="common-25"
                                  >
                                    {row?.order_id}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    component="td"
                                    scope="row"
                                    className="common-25"
                                  >
                                    {row?.product?.product_actual_name}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    component="td"
                                    scope="row"
                                    className="common-25"
                                  >
                                    {row?.currency} {row?.final_price}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    component="td"
                                    scope="row"
                                    className="common-25"
                                  >
                                    <img
                                      src={row?.product?.product_picture}
                                      alt={row?.product?.product_actual_name}
                                      style={{ width: "3rem", height: "3rem" }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    component="td"
                                    scope="row"
                                    className="common-25"
                                  >
                                    <img
                                      src={row?.choose_product_photo}
                                      alt={row?.product?.product_actual_name}
                                      style={{ width: "3rem", height: "3rem" }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    component="td"
                                    scope="row"
                                    className="common-25"
                                  >
                                    {row?.user_choose_image ? <img
                                      src={row?.user_choose_image}
                                      alt={row?.product?.product_actual_name}
                                      style={{ width: "3rem", height: "3rem" }}
                                    /> : "N/A"}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    component="td"
                                    scope="row"
                                    className="common-25"
                                  >
                                    {row?.quantity}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    component="td"
                                    scope="row"
                                    className="common-25"
                                  >
                                    <MDBox ml={-1} >
                                    <MDButton
                                            onClick={() => handleOrderStatusOpenModal(row._id)} 
                                    >
                                      <MDBadge
                                        badgeContent={!row?.order_progress_status ? 'New' : row?.order_progress_status }
                                        color={row?.order_progress_status === "completed" ? "success" : (row?.order_progress_status === "processing" ? "pending" : "secondary")}
                                        // variant="gradient"
                                        size="sm"
                                      />
                                      </MDButton>
                                    </MDBox>
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    <CUDialog
                                      openDialog={openDialog}
                                      handleCloseDialog={handleCloseDialog}
                                      deleteId={deleteCoupon}
                                      headingMessage="Confirmation"
                                      bodyMessage="Are you sure want to delete ?"
                                    />
                                    <MDBox
                                      display="flex"
                                      alignItems="center"
                                      mt={{ xs: 2, sm: 0 }}
                                      ml={{ xs: -1.5, sm: 0 }}
                                    >
                                      <MDBox>
                                        <Tooltip title={"Order Details"}>
                                          <MDButton
                                            variant="text"
                                            color="error"
                                            onClick={() => handleOpenModal(row._id)} // onClick={() => handleViewOders(row._id)}
                                          >
                                            <RemoveRedEyeIcon />
                                            {/* view */}
                                          </MDButton>
                                        </Tooltip>
                                      </MDBox>
                                      <MDBox>
                                        {row?.user_choose_image ? <ImageDownloader imageUrl={row?.user_choose_image} fileName={row?.user_choose_image.split("/")[row?.user_choose_image.split("/").length - 1]} /> : <MDBadge
                                          badgeContent={"N/A"}
                                          color={"error"}
                                          // variant="gradient"
                                          size="sm"
                                        />
                                        }
                                      </MDBox>
                                    </MDBox>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))
                            ) : (
                              <StyledTableRow
                                key="1"
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell colSpan="9">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "100%",  // Ensures the Box takes full width of the TableCell
                                      height: "100%", // Ensures the Box takes full height of the TableCell
                                    }}
                                  >
                                    No Order Found
                                  </Box>
                                </TableCell>
                              </StyledTableRow>
                            )
                          )}
                          <OrderDetailsModal
                            open={modalOpen}
                            onClose={handleCloseModal}
                            orderDetails={selectedOrder}
                          />

                          <OrderStatusModal
                            open={modalOrderStatusOpen}
                            onClose={handleOrderStatusCloseModal}
                            orderDetails={selectedOrderStatus}
                          />
                          
                        </TableBody>
                        <TableFooter>
                        <tr>
                          <td colSpan={4}>
                            <CUPagination
                              numberOfPages={page.numberOfPages}
                              currentPage={page.currentPage}
                              onChange={handlePaginationChange}
                            />
                          </td>
                        </tr>
                        
                        </TableFooter>
                      </Table>
                    </TableContainer>
                    {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
                    {/* <Card></Card> */}
                  {/* </MDBox> */}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default OrdersManagements;
